import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import * as Components from '@p14/components';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/architecture';

export const Root = styled.section`
  display: flex;
  padding: 80px 0;
  max-width: 870px;
  margin: 0 auto;
  flex-wrap: nowrap;

  @media ${config.media.tabletOnly} {
    max-width: 634px;
  }

  @media ${config.media.mobile} {
    display: block;
    padding: 60px 0 0;
  }
`;

export const Article = styled.article`
  @media ${config.media.desktopOnly} {
    flex: 1 1 400px;

    &:first-child {
      margin-right: 70px;
    }
  }

  @media ${config.media.tabletOnly} {
    flex: 1 1 283px;

    &:first-child {
      margin-right: 66px;
    }
  }

  @media ${config.media.mobile} {
    width: 100%;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h2`
  font-family: 'Austin';
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 19px;
  text-align: center;
  letter-spacing: 0.12px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  line-height: 0.8;
  color: ${config.colors.main};
`;

export const Figure = styled.figure`
  margin: 0;
  overflow: hidden;
  position: relative;

  & img {
    max-width: 100%;
    margin: 0 0 30px;
  }
`;

export const Figcaption = styled.figcaption`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-left: 40px;
  font-family: ${config.fonts.sansSerif};
  color: ${config.colors.text};

  @media ${config.media.tabletOnly} {
    text-align: left;
    padding-left: 33px;
  }

  @media ${config.media.mobile} {
    padding-left: 0;
  }
`;

export const Section = ({title, image, text}) => (
  <Article>
    <Title>{title}</Title>
    <Figure>
      <img src={image} alt="title"/>
      <Figcaption dangerouslySetInnerHTML={{__html: text}}/>
    </Figure>
  </Article>
);

export const Sections = ({tr}) => (
  <Components.Container>
    <Root>
      {tr('sections').map((section, index) => <Section {...section} key={index}/>)}
    </Root>
  </Components.Container>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Sections);
