import React from 'react';
import {compose} from 'recompose';
import Layout from '@p14/layouts';
import * as Modules from './_';
import * as HOC from '@p14/HOC';
import * as Components from '@p14/components';
import data from '@p14/texts/architecture';

export const meta = {
  title: '«Пироговская, 14» — проект Green Design Society, маленькое частное сообщество в центре Москвы.',
  keywords: 'Пироговская, 14,  Хамовники, архитектура, дизайн, проект',
  description: 'Хамовники — один из самых харизматичных районов Москвы. Когда проектируешь дом в таком месте, необходимо думать о контексте: о том, что новое здание даст улице и району, поэтому мы пригласили Green Design Society, Andrew Macken и Hesse Macken Studio.',
};

export const Architecture = ({tr, data}) => (
  <Layout>
    <Components.H1>{tr('title')}</Components.H1>
    <Modules.Slider slides={data.allStrapiArchitectureslides.edges.map(({node}) => node)}/>
    <Modules.Sections/>
    <Components.Blockquote
      quote={tr('quote')}
      captions={tr('quoteCaptions')}
      />
  </Layout>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Architecture);
