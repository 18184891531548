import {graphql} from 'gatsby';

import Architecture from '../screens/architecture';
export default Architecture;

export const query = graphql`
  query {
    allStrapiArchitectureslides {
      edges {
        node {
          id,
          Image {
            childImageSharp {
              fixed(width: 790, height: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
