export default {
  ru: {
    title: 'Маленькое частное сообщество в центре Москвы',
    quote: 'Хамовники – один из самых харизматичных районов Москвы. Когда&nbsp;проектируешь дом в таком месте, необходимо думать о&nbsp;контексте: о том, что новое здание даст улице и району. Поэтому&nbsp;у&nbsp;«Пироговской, 14» девять этажей – больше здесь просто нельзя: и фасад мы сделали несомненно московский по духу – в&nbsp;элегантной современной версии.',
    quoteCaptions: ['Nick Butler', 'Green Design Society'],
    sections: [{
      title: 'Фасад',
      image: '/static/architecture/facad.jpg',
      text: 'Для фасада мы использовали кирпич, изготовленный на заказ старинным способом ручной формовки. Благородный материал песочного цвета рассчитан на многие десятилетия&nbsp;– это стены, которые со временем станут только лучше',
    }, {
      title: 'Дача в городе',
      image: '/static/architecture/landscape.jpg',
      text: 'Ваш частный сад с деревьями и креслами<br/>для отдыха. Место, где летом можно гулять босиком или сидеть с чашкой кофе. Пожалуй, самая большая роскошь, которая возможна<br/>в центре Москвы',
    }],
  },

  en: {
    title: 'Маленькое частное сообщество в центре Москвы',
    quote: 'Хамовники – один из самых харизматичных районов Москвы. Когда проектируешь дом в таком месте, необходимо думать о контексте: о том, что новое здание даст улице и району. Поэтому у «Пироговской, 14» девять этажей – больше здесь просто нельзя: и фасад мы сделали несомненно московский по духу – в элегантной современной версии.',
    quoteCaptions: ['Nick Butler', 'Green Design Society'],
    sections: [{
      title: 'Фасад',
      image: '/static/architecture/facad.jpg',
      text: 'Для фасада<br/>мы использовали кирпич, изготовленный на заказ старинным способом ручной формовки. Благородный материал песочного цвета рассчитан на многие десятилетия – это стены, которые со временем<br/>станут только лучше',
    }, {
      title: 'Дача в городе',
      image: '/static/architecture/landscape.jpg',
      text: 'Ваш частный сад с деревьями и креслами для отдыха. Место, где летом можно гулять босиком или сидеть с чашкой кофе. Пожалуй, самая большая роскошь, которая возможна в центре Москвы',
    }],
  },
};
